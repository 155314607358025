.menu-cs {
    margin: 0;
    .cs-container {
        color: $white;
        display: grid;
        width: 100%;
        text-align: center;
        @include tablet {
            order: 1;
            padding: 0 3em 0 0;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 0 2em;
            text-align: left;
        }
    }
    .cs-image {
        svg {
            max-width: 100%;
        }
    }
    .cs-text {
        .title {
            margin: 0 0 1.5rem;
            font-family: $body-font;
            font-weight: bold;
            @include fontSize(2);
        }
        .body {
            margin: 0 0 2rem;
            font-family: $body-font;
            @include fontSize(1.6);
            &:last-child {
                margin: 0;
            }
        }
        .menu-ghost-button {
            @include fontSize(1.4);
        }
    }
}
