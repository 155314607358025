#nav-menu-search {
    margin: 0;
    padding: $mobile-padding $mobile-padding 5rem;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: none;
    z-index: 10;
    box-shadow: 0 6px 15px #666;
    clip-path: inset(0 0 -30px 0);
    .close-content {
        margin: 0 0 1em 0;
        padding: 0;
        text-align: right;
    }
    .content {
        margin: 0 auto;
        @include max-wrap;
        display: grid;
        @include tablet {
            grid-template-columns: 1fr 1fr;
            align-items: stretch;
            justify-items: center;
            justify-content: center;
        }
    }
    .search-form {
        margin:  0 auto 3em;
        color: $white;
        width: 100%;
        @include tablet {
            margin: 0;
            order: 2;
            border-left: 1px $body-link-color solid;
            padding: 0 0 0 3em;
            width: 100%;
        }
        form {
            margin: 0 0 2em;
        }
        .title {
            margin: 0 auto 1.5rem;
            color: $white;
            font-weight: bold;
            @include fontSize(3);
            font-family: $heading-font;
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
        .search-fields {
            margin: 0 0 1.5rem;
            padding: 0;
            list-style: none;
            display: grid;
            align-items: stretch;
            gap: 1em 0;
            @include tablet {
                display: flex;
                gap: 0 10px;
                > li:first-child {
                    flex: 1;
                }
            }
            .search-field {
                padding: 10px 20px 10px 50px;
                background: url('../Images/header_nav_search-field-icon.svg') $white no-repeat 15px center / 22px 23px;
            }
        }
        .search-links {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            @include tablet {
                text-align: left;
            }
            li {
                margin: 0 0 1em;
                display: block;
                font-weight: bold;
                @include fontSize(2);
                &:last-child {
                    margin: 0;
                }
            }
            a {
                color: $white;
                font-weight: bold;
                text-decoration: none;
                @include fontSize(2);
                &:focus, &:hover {
                    text-decoration: underline;
                }
            }
        }
        .additional-logins-accordion {
            margin: 0;
            border-bottom: 1px $body-link-color solid;
            &.active .accordion-button:after {
                transform: scale(1, -1);
                transition: transform 0.25s ease;
            }
            .accordion-button {
                margin: 0;
                padding: 0 23px 0.25em 0;
                color: $white;
                font-weight: bold;
                @include fontSize(3);
                font-family: $heading-font;
                width: 100%;
                text-align: left;
                position: relative;
                @include tablet {
                    padding: 0 40px 0.25em 0;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-image: url('../Images/header_nav_accordion-arrow.svg');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center 55%;
                    background-size: auto 30%;
                    transform: scale(1, 1);
                    transition: transform 0.25s ease;
                }
            }
            .accordion-content {
                display: none;
            }
            .accounts {
                margin: 0;
                padding: 2em 0;
                list-style: none;
                display: grid;
                gap: 1.5em 1.5em;
                grid-template-columns: 1fr 1fr;
                a {
                    color: $white;
                    text-decoration: underline;
                    font-weight: bold;
                    @include fontSize(2);
                }
            }
        }
    }
}

// Body Search Form
.block-search-form {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5em;
    > .pad {
        margin: 0 auto;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        > .content {
            margin: 0 auto;
            width: 100%;
            max-width: 700px;
        }
    }
    form.body-search {
        margin: 0;
        .title {
            margin: 0 auto 1.5rem;
            color: $body-link-color;
            font-weight: bold;
            @include fontSize(3);
            font-family: $heading-font;
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
        .search-fields {
            margin: 0 0 1.5rem;
            padding: 0;
            list-style: none;
            display: grid;
            align-items: stretch;
            gap: 1em 0;
            @include tablet {
                display: flex;
                gap: 0 10px;
                > li:first-child {
                    flex: 1;
                }
            }
            .body-search-field {
                @include fontSize(2.2);
                padding: 10px 20px 10px 50px;
                border-radius: 50px;
                border-width: 2px;
                border-style: solid;
                border-color: $body-link-color;
                color: $body-font-color;
                width: 100%;
                height: 100%;
                background: url('../Images/header_nav_search-field-icon.svg') $white no-repeat 15px center / 22px 23px;
                &:focus, &:hover {
                    border-color: $secondary-color;
                }
            }
            .body-search-button {
                margin: 0;
                display: inline-block;
                background-color: $secondary-color;
                color: $black;
                @include fontSize(2.2);
                padding: 10px 20px;
                font-weight: 700;
                text-align: center;
                position: relative;
                line-height: 1.3;
                transition: all 0.5s ease;
                border-radius: 30px;
                border-width: 2px;
                border-color: $secondary-color;
                border-style: solid;
                border-radius: 30px;
                width: 100%;
                height: 100%;
                transition: background-color 0.5s ease, border-color 0.5s ease;
                &:focus, &:hover {
                    background-color: darken($secondary-color, 10);
                    border-color: $black;
                    transition: background-color 0.5s ease, border-color 0.5s ease;
                }
            }
        }
    }
}

// Search Results Page - Result Tiles
.block-search-results {
    .search-results-title {
        margin: 0 auto 1em;
    }
    .row.search-tile {
        margin: 0 auto 3.5em;
        .search-tile-title {
            margin: 0 auto 0.5em;
        }
    }
}

// Search Results Page - Pagination
.pagination-container {
    margin: 0 auto 2.5em;
    .pagination {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 2.5em 2.5em;
        li {
            margin: 0;
            padding: 0;
            a, span {
                margin: 0;
                padding: 0 3px 3px 3px;
                position: relative;
                display: inline-block;
                text-align: center;
                width: 100%;
                height: 100%;
                font-family: $body-font;
                font-weight: bold;
                font-size: 2rem;
                &:after {
                    content: "";
                    display: inline-block;
                    margin: 0 auto;
                    padding: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 5px;
                    border-radius: 5px;
                    background-color: transparent;
                    transition: background-color 0.5s ease;
                }
            }
            &:not([class]) {
                a, span {
                    color: #80AACC !important;
                }
            }
            &.active {
                a, span {
                    color: $primary-color !important;
                    &:after {
                        background-color: $secondary-color;
                        transition: background-color 0.5s ease;
                    }
                }
            }
        }
    }
}
