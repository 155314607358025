// Forms
//
form {
    @include max-wrap;
    padding: 0 $mobile-padding;
}

.form-field {
    margin-bottom: 15px;
    text-align: left;
}

.form-control {
    width: 100%;
    border: solid 1px #CCC;
    border-radius: 5px;
    padding: 0 7px;
    color: $black;

    @include placeholder {
        color: #808080;
    }
}

.field-validation-error {
    color: #C00;
}

.ktc-checkbox {
    display: block;
    margin-bottom: .5em;
}

input[type="checkbox"].form-control, input[type="radio"] {
    width: auto;
    margin-right: 5px;
}

textarea.form-control {
    height: 200px;
}

input[type="text"].form-control, input[type="email"].form-control, select.form-control {
    height: 50px;
}

form input[type="submit"] {
    display: inline-block;
    font-size: 1.6rem;
    padding: 0.7em 3em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    position: relative;
    line-height: 1.3;
    transition: all 0.5s ease;
    border-radius: 30px;
    background: $secondary-color;
    color: $black;
    border-color: $secondary-color;

    &:hover {
        border-color: $black;
    }

    display: block;
    margin: 0 auto 1em;
}

.control-label {
    font-size: 1.4rem;
    margin-bottom: 0.3em;
    display: block;
}



.form-split-section {

    > .content {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr;

        @include tablet {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.form-stretch-section {

    > .content {

        @include tablet {
            display: flex;
            column-gap: 30px;
            row-gap: 30px;

            > div {
                width: 100%;
            }
        }
    }
}


.formwidget-submit-text {
    text-align: center;
    margin-bottom: 2em;
    font-weight: 700;
}

