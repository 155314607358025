.btn-cookie-policy {
    display: inline-block;
    background-color: transparent;
    color: $white;
    font-size: 1.4rem;
    padding: 0.5em 3em;
    font-weight: bold;
    text-align: center;
    position: relative;
    line-height: 1.3;
    transition: all 0.5s ease;
    border-width: 2px;
    border-color: $white;
    border-style: solid;
    border-radius: 30px;
    transition: background-color 0.5s ease, border-color 0.5s ease;
    &:focus, &:hover {
        background-color: darken($secondary-color, 10);
        border-color: $black;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        text-decoration: none;
    }
}

#cookie-policy {
    margin: 0;
    padding: 1em;
    text-align: right;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    min-height: 50px;
    z-index: 15;
    background-color: $green-background-color;
    .alert-close {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: url('../Images/header_alert_close-alert.svg') transparent no-repeat center / 29px 29px;
        transform: scale(1);
        transition: transform 0.5s ease;
        &:focus, &:hover {
            transform: scale(1.1);
            transition: transform 0.5s ease;
        }
        @include tablet {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) scale(1);
            transition: transform 0.5s ease;
            &:focus, &:hover {
                transform: translateY(-50%) scale(1.1);
                transition: transform 0.5s ease;
            }
        }
    }
    .body {
        margin: 0;
        padding: 0;
        padding-right: 75px;
        list-style: none;
        font-size: 1.4rem;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 0 1em;
        justify-content: center;
        color: $white;
        > *:last-child {
            margin: 0;
        }
    }
    a {
        font-size: inherit;
        font-weight: bold;
        text-decoration: underline;
        color: $white;
    }
}
