#header-container {
    margin: 0;
    padding: 0;
    position: relative;
    @include tablet {
        position: sticky;
        top: -85px;
        z-index: 100;
        transition: top 0.5s ease;
        &.scroll-show {
            top: 0px;
            transition: top 0.5s ease;
        }
    }
}

// Menu Buttons
.btn-login {
    color: $primary-color;
    padding: 0;
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: normal;
    border: none;
    line-height: 1.4;
    vertical-align: middle;
    &:focus, &:hover {
        text-decoration: underline;
    }
    @include tablet {
        display: inline-block;
        background-color: $secondary-color;
        color: $black;
        font-size: 1.4rem;
        padding: 0.7em 4em;
        font-weight: bold;
        text-align: center;
        position: relative;
        line-height: 1.3;
        transition: all 0.5s ease;
        border-width: 2px;
        border-color: $secondary-color;
        border-style: solid;
        border-radius: 30px;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        &:focus, &:hover {
            background-color: darken($secondary-color, 10);
            border-color: $black;
            transition: background-color 0.5s ease, border-color 0.5s ease;
            text-decoration: none;
        }
    }
}
.menu-button {
    margin: 0 !important;
    display: inline-block;
    background-color: $secondary-color;
    color: $black;
    @include fontSize(2.2);
    padding: 10px 20px;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.3;
    transition: all 0.5s ease;
    border-radius: 30px;
    border-width: 2px;
    border-color: $secondary-color;
    border-style: solid;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    transition: background-color 0.5s ease, border-color 0.5s ease;
    &:focus, &:hover {
        background-color: darken($secondary-color, 10);
        border-color: $black;
        transition: background-color 0.5s ease, border-color 0.5s ease;
    }
}
.menu-ghost-button {
    margin: 0 !important;
    display: inline-block;
    background-color: transparent;
    color: $white;
    @include fontSize(2.2);
    padding: 0.5em 2.5em;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.3;
    transition: all 0.5s ease;
    border-radius: 30px;
    border: 2px $secondary-color solid;
    transition: background-color 0.5s ease, color 0.5s ease;
    &:focus, &:hover {
        background: $secondary-color;
        color: $black;
        transition: background-color 0.5s ease, color 0.5s ease;
    }
}
.menu-close {
    width: 50px;
    height: 50px;
    transform: scale(1);
    transition: transform 0.5s ease;
    background: url('../Images/header_nav_menu-close.svg') transparent no-repeat center / 29px 29px;
    &:focus, &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
    }
}

// Menu Inputs
.menu-field {
    margin: 0;
    @include fontSize(2.2);
    padding: 10px 20px;
    border-radius: 50px;
    border-width: 2px;
    border-style: solid;
    border-color: $white;
    color: $body-font-color;
    width: 100%;
    height: 100%;
    &:focus, &:hover {
        border-color: $secondary-color;
    }
}

// The Menu Itself
#header-standard {
    margin: 0;
    padding: 0;
    height: 131px;
    background-color: $primary-color;
    position: relative;
    @include tablet {
        padding: 0 15px;
        height: 85px;
    }
    .header-desktop {
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        height: 131px;
        @include tablet {
            height: 85px;
            flex-wrap: nowrap;
            align-items: center;
        }
    }
    .logo {
        padding: 5px $mobile-padding;
        flex-basis: 100%;
        order: 2;
        text-align: center;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet {
            padding: 0;
            flex: 1;
            flex-basis: 30%;
            text-align: left;
            order: 1;
            height: auto;
            display: list-item;
        }
        a {
            display: inline-block;
            transform: scale(1);
            transition: transform 0.5s ease;
            &:focus, &:hover {
                transform: scale(1.05);
                transition: transform 0.5s ease;
            }
        }
        img {
            width: 100%;
            max-width: 304px;
            height: auto;
            @include tablet {
                max-width: 356px;
            }
        }
    }
    .navigation {
        flex: 1;
        flex-basis: 100%;
        text-align: center;
        order: 3;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet {
            flex-basis: 40%;
            order: 2;
            display: list-item;
            height: auto;
        }
    }
    .menus {
        flex: 1;
        flex-basis: 100%;
        text-align: center;
        justify-content: space-between;
        background-color: $yellow-background-color;
        order: 1;
        height: auto;
        @include tablet {
            flex: 1;
            flex-basis: 30%;
            text-align: right;
            justify-content: center;
            background-color: $primary-color;
            order: 3;
        }
        > ul {
            margin: 0 auto;
            padding: 0;
            display: inline-flex;
            list-style: none;
            grid-gap: 0 2.5em;
            align-items: center;
            > li {
                margin: 0;
                text-align: center;
                @include mobile {
                    display: flex;
                    align-items: center;
                }
                a:not(.btn-login), .nav-button {
                    margin: 0;
                    font-weight: normal;
                    display: inline-block;
                    color: $primary-color;
                    font-size: 1.4rem;
                    vertical-align: middle;
                    @include tablet {
                        color: $white;
                        padding: 8px 0 0 0;
                    }
                    img {
                        display: none;
                        @include tablet {
                            display: inline-block;
                            height: 19px;
                            width: 19px;
                            margin: 0 0 2px 0;
                            transform: scale(1);
                            transition: transform 0.5s ease;
                        }
                    }
                    br {
                        display: none;
                        @include tablet {
                            display: inline;
                        }
                    }
                    &:focus, &:hover {
                        text-decoration: underline;
                        img {
                            transform: scale(1.15);
                            transition: transform 0.5s ease;
                        }
                    }
                }
                .btn-login {

                }
            }
        }
    }
    .dropdown-container {
        margin: 0 auto;
        padding: 0 1em;
        display: inline-grid;
        list-style: none;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 0.5em;
        width: 100%;
        max-width: 300px;
        height: auto;
        @include tablet {
            max-width: 500px;
            height: 85px;
        }
        @include responsive(1200px) {
            grid-gap: 0 2em;
        }
        @include responsive(1300px) {
            grid-gap: 0 4em;
        }
        > li {
            font-size: 1.6rem;
            color: $white;
            font-weight: bold;
            height: auto;
            display: flex;
            align-items: center;
            @include tablet {
                height: 85px;
            }
            @include responsive(1200px) {
                font-size: 2rem;
            }
            &.active .nav-menu-title:after {
                transform: scale(1, -1);
                transition: transform 0.25s ease;
            }
        }
        .nav-menu-title {
            margin: 0 auto;
            padding: 0 1.25em 0 0;
            position: relative;
            display: inline-block;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1em;
                height: 100%;
                background-image: url('../Images/header_nav_dropdown-arrow.svg');
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center 55%;
                background-size: auto 33%;
                transform: scale(1, 1);
                transition: transform 0.25s ease;
            }
        }
    }
    .dropdown-menu {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        height: auto;
        background: $primary-color;
        z-index: 9;
        box-shadow: 0 6px 15px #666;
        clip-path: inset(0 0 -30px 0);
        display: none;
        ul {
            margin: 0 auto;
            padding: 5rem $mobile-padding 10rem;
            list-style: none;
            width: 100%;
            max-width: 1200px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 2em 0;
            @include responsive(400px) {
                grid-template-columns: 1fr 1fr;
                grid-gap: 2em 1em;
            }
            @include responsive(900px) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 3em 3em;
            }
            @include responsive(1100px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 3em 3em;
            }
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 10px;
            color: $white;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.1;
            @include tablet {
                gap: 0 15px;
                font-size: 2rem;
            }
            &:focus, &:hover {
                text-decoration: underline;
                img {
                    animation-duration: 1s;
                    animation-iteration-count: 1;
                    animation-name: menu-icon-rock;
                }
            }
            img {
                margin: 0;
                width: 60px;
                height: 60px;
                @include tablet {
                    width: 70px;
                    height: 70px;
                }
            }
            .link-text {
                width: calc(100% - 70px);
                text-align: left;
                line-height: 1.35;
            }
        }
    }
}

@keyframes menu-icon-rock {
    0% {
        transform: scale(1) rotate(0);
    }
    25% {
        transform: scale(1.15) rotate(5deg);
    }
    50% {
        transform: scale(1.15) rotate(-5deg);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}
