#nav-menu-login {
    margin: 0;
    padding: $mobile-padding $mobile-padding 5rem;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: none;
    z-index: 10;
    box-shadow: 0 6px 15px #666;
    clip-path: inset(0 0 -30px 0);
    .close-content {
        margin: 0 0 1em 0;
        padding: 0;
        text-align: right;
    }
    .content {
        margin: 0 auto;
        @include max-wrap;
        display: grid;
        @include tablet {
            grid-template-columns: 1fr 1fr;
            align-items: stretch;
            justify-items: center;
            justify-content: center;
        }
    }
    .login-form {
        margin: 0 auto 3em;
        color: $white;
        width: 100%;
        @include tablet {
            margin: 0;
            order: 2;
            border-left: 1px $body-link-color solid;
            padding: 0 0 0 3em;
            width: 100%;
        }
        form {
            margin: 0 0 3em;
        }
        .title {
            margin: 0 auto 1.5rem;
            color: $white;
            font-weight: bold;
            @include fontSize(3);
            font-family: $heading-font;
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
        .login-fields {
            margin: 0 0 1.5rem;
            padding: 0;
            list-style: none;
            display: grid;
            align-items: stretch;
            gap: 1em 0;
            @include tablet {
                display: flex;
                gap: 0 10px;
                > li:first-child {
                    flex: 1;
                }
            }
        }
        .login-links {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            @include tablet {
                text-align: left;
            }
            li {
                display: inline-block;
                &:after {
                    content: "|";
                    padding: 0 1em;
                    display: inline-block;
                }
                &:last-child:after {
                    display: none;
                }
            }
            a {
                color: $white;
                text-decoration: underline;
                @include fontSize(1.8);
            }
        }
        .additional-logins-accordion {
            margin: 0;
            border-bottom: 2px $body-link-color solid;
            &.active .accordion-button:after {
                transform: scale(1, -1);
                transition: transform 0.25s ease;
            }
            .accordion-button {
                margin: 0;
                padding: 0 23px 0.25em 0;
                color: $white;
                font-weight: bold;
                @include fontSize(3);
                font-family: $heading-font;
                width: 100%;
                text-align: left;
                position: relative;
                @include tablet {
                    padding: 0 40px 0.25em 0;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-image: url('../Images/header_nav_accordion-arrow.svg');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center 55%;
                    background-size: auto 30%;
                    transform: scale(1, 1);
                    transition: transform 0.25s ease;
                }
            }
            .accordion-content {
                display: none;
            }
            .accounts {
                margin: 0;
                padding: 2em 0;
                list-style: none;
                display: grid;
                gap: 1.5em 1.5em;
                grid-template-columns: 1fr 1fr;
                a {
                    color: $white;
                    text-decoration: underline;
                    font-weight: bold;
                    @include fontSize(2);
                }
            }
        }
    }
}
