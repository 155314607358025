footer#footer-standard {
    margin: 0;
    padding: 0;
    color: $white;
    // Universal link styles for the footer, customize furthewr per section.
    a {
        color: $white;
        text-decoration: none;
        display: inline-block;
        &:focus, &:hover {
            text-decoration: underline;
            img {
                transform: scale(1.1);
                transition: transform 0.5s ease;
            }
        }
        img {
            transform: scale(1);
            transition: transform 0.5s ease;
        }
    }
    .footer-divider {
        margin: 0;
        overflow: hidden;
        text-align: center;
        background-color: $white;
        height: 10vw;
        background-image: url('../Images/footer_curve.svg');
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 200% 100%;
        @include tablet {
            height: 5vw;
            background-size: 100% 100%;
        }
    }
    > .content {
        margin: 0 auto;
        padding: 1.5rem $mobile-padding 5rem;
        background: $primary-color;
        @include tablet {
            padding: 5rem $mobile-padding 5rem;
        }
        .inner-content {
            margin: 0 auto;
            @include max-wrap;
        }
    }
    .footer-top {
        margin: 0 0 2rem;
        display: block;
        text-align: center;
        @include tablet {
            margin: 0 0 8rem;
            display: flex;
            align-items: stretch;
            text-align: left;
        }
    }
    .footer-nav-mobile {
        margin: 0 0 4rem;
        display: inline-grid;
        gap: 4rem 0;
        @include tablet {
            display: none;
        }
        .accordion {
            &.active .accordion-button .text:after {
                transform: scale(1,-1);
                transition: transform .25s ease;
            }
        }
        .accordion-button {
            margin: 0;
            padding: 0;
            width: 100%;
            font-size: 2.2rem;
            font-family: $heading-font;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 0 1.5rem;
            .text {
                padding: 0 32px 0 0;
                display: inline-block;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 16px;
                    height: 100%;
                    background-image: url('../Images/header_nav_dropdown-arrow.svg');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center 55%;
                    background-size: 16px 9px;
                    transform: scale(1,1);
                    transition: transform .25s ease;
                }
            }
        }
        .accordion-content {
            margin: 0;
            padding: 0 0 2em 0;
            display: none;
        }
        ul {
            margin: 0 0 0 calc(66px + 1.5rem);
            padding: 0;
            list-style: none;
            display: grid;
            gap: 1rem;
            text-align: left;
        }
    }
    .footer-nav-desktop {
        display: none;
        @include tablet {
            margin: 0;
            padding: 0 5rem 0 0;
            border-right: 1px $body-link-color solid;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-basis: 70%;
        }
        .col {
            display: flex;
            align-items: flex-start;
            gap: 0 1.5rem;
        }
        img {
            width: 100%;
            max-width: 66px;
            height: auto;
            flex-basis: 66px;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: grid;
            gap: 0;
            flex-basis: calc(100% - 1.5rem - 66px);
        }
        li {
            font-size: 1.4rem;
            font-weight: normal;
            &:first-child {
                font-size: 2.3rem;
                font-family: $heading-font;
                font-weight: bold;
            }
        }
        a {
            font-size: 1.4rem;
            font-weight: normal;
        }
    }
    .footer-details {
        margin: 0;
        padding: 0;
        @include tablet {
            padding: 0 0 0 5rem;
            flex-basis: 30%;
        }
    }
    .footer-social {
        margin: 0 0 4rem;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem 1.5rem;
        
        @include tablet {
            margin: 0 0 1.5rem;
            justify-content: flex-start;
        }
        li {
            img {
                width: 36px !important;
            }
            &:nth-child(1) {
                img {
                    width: 26px !important;
                }
            }
        }
        
    }
    .footer-info {
        margin: 0 0 4rem;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem 2rem;
        @include tablet {
            margin: 0;
            display: grid;
            justify-content: flex-start;
            gap: 1.2rem 0;
        }
        li, a {
            font-size: 1.2rem;
            @include tablet {
                font-size: 1.4rem;
            }
        }
    }
    .footer-bottom {
        margin: 0;
        display: grid;
        gap: 1.5rem 0;
    }
    .legal-links {
        margin: 0 0 4rem;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem 2rem;
        @include tablet {
            margin: 0;
            justify-content: flex-start;
            gap: 1rem 2rem;
        }
        li, a {
            font-size: 1.2rem;
        }
    }
    .footer-bugs {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem 2rem;
        @include tablet {
            justify-content: flex-start;
            gap: 1rem 2rem;
        }
        li, a {
            font-size: 1.2rem;
        }
    }
}
