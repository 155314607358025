// Smooth scrolling when anchoring to an element.
html {
    scroll-behavior: smooth;
}
@include tablet {
    *[id], *[data-jumplink] {
        scroll-margin-top: 85px;
    }
}

// Body Content Links
//
main a:not([class]),
main a[class="speedbump"],
main a[class=""],
main a[class="email_bump"] {
  text-decoration: underline;
  color: $primary-color;
}

// Special Text Styling
//
.text-caps {
  text-transform: uppercase;
}

// Video List Within a Content Block
//
.content .video-frame {
  display: inline-block;
  margin: 0 3rem 3rem 0;
}

.accessibility-statement {
    text-align: center;
}

// All the background colors
.block-background-white {
    background-color: $white !important;
}
.block-background-yellow {
    background-color: $yellow-background-color !important;
}
.block-background-green {
    background-color: $green-background-color !important;
}
.block-background-blue {
    background-color: $blue-background-color !important;
}

// All the classes to add curves
.curve-top-yellow-no-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-yellow.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-top-yellow-with-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-yellow-stroke-blue.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-top-green-no-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-green.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-top-green-with-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    bottom: -1px;
    background-image: url('../Images/body_curve-green-stroke-yellow.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-top-blue-no-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-light-blue.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-top-blue-with-line:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-light-blue-stroke-green.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-bottom-yellow-no-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-yellow.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-bottom-yellow-with-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-yellow-stroke-blue.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150% 100%;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-bottom-green-no-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-green.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center -1px;
    background-size: 150% 100%;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-bottom-green-with-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    bottom: 7vw;
    background-image: url('../Images/body_curve-green-stroke-yellow.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center -1px;
    background-size: 150% 100%;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
        bottom: 1px;
    }
}
.curve-bottom-blue-no-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-light-blue.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center -1px;
    background-size: 150% 100%;
    top: -1px;
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}
.curve-bottom-blue-with-line:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: 10vw;
    background-image: url('../Images/body_curve-light-blue-stroke-green.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center -1px;
    background-size: 150% 100%;
    transform: scale(-1, -1);
    @include tablet {
        height: 5vw;
        background-size: 100% 100%;
    }
}


// Overlap the last block in main with the footer shape so if the last section has a background color, it flows under the footer shape.
main > div:last-child:not(.block-page-not-found),
main > div:last-child:not(.block-page-not-found) > div:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
main > div:last-child > div:last-child > div.pad {
    margin-bottom: -10vw !important;
    padding-bottom: 20vw !important;
    @include tablet {
        margin-bottom: -5vw !important;
        padding-bottom: 10vw !important;
    }
}


// Fix for odd gaps on certain screen sizes


.curve-bottom-yellow-no-line, .curve-bottom-yellow-with-line, .curve-bottom-green-no-line, .curve-bottom-green-with-line, .curve-bottom-blue-no-line, .curve-bottom-blue-with-line {
    &:after {
        top: -1px;
    }
}

.curve-top-yellow-no-line, .curve-top-yellow-with-line, .curve-top-green-no-line, .curve-top-green-with-line, .curve-top-blue-no-line, .curve-top-blue-with-line {
    &:before {
        bottom: -1px;
    }
}

.mod-location-details .loc-team-area.margin-bottom-none.curve-top-yellow-no-line.lastBlock {
    margin-bottom: -1px !important;
}

.footer-divider {
    bottom: -1px;
    position: relative;
}